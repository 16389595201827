<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">阅卷列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{query.markType=='01'?'阅卷':'复核'}}</a>
        </span>
      </div>
      <div class="answerPage">
        <div class="content">
          <div class="content_row">
            <div class="mr20">
              <div class="card_box w280" style="border-radius:4px 4px 0 0;">
                <h3>答题卡</h3>
                <div class="answerCards">
                  <div class="answerCardsItem" v-for="(item,index) in markInfo.partitionList" :key="item.partitionSortId">
                    <div class="itemTitle">{{item.partitionName}}（共{{item.questionNum}}题，合计{{item.totalScore}}分）</div>
                    <div class="btnList">
                      <div class="btnItem" v-for="(btnItem,btnindex) in item.answerBOList" :class="classFliter(btnItem)" @click="btnItemClick(btnItem,index)" :key="btnItem.paperQuestionId">
                        {{btnindex+1}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="answerCardsBtn">
                <template v-if="query.markType=='02'">
                  <div class="noAnswer">未作答</div>
                  <div class="correct">正确</div>
                  <div class="partOfRight">部分正确</div>
                  <div class="error">错误</div>
                </template>
                <template v-if="query.markType=='01'">
                  <div class="didNotRead">未阅</div>
                  <div class="haveRead">已阅</div>
                </template>
              </div>
            </div>
            <div class="mr20">
              <div class="card_box w630" style="min-height: calc(100% - 160px);">
                <h3>{{ currentParper.partitionName }}（共{{ currentParper.questionNum }}题，合计{{ currentParper.totalScore }}分）</h3>
                <div :class="currentQuestion.parentQuestionType?'questionType hasParent':'questionType'">
                  <div class="parentQuestionTitle" v-if="currentQuestion.parentQuestionType == '70'||currentQuestion.parentQuestionType == '80'">
                    <span class="questionTypeTips" style="min-width: 42px">组合</span>
                    <div class="questionTitleText" v-html="currentQuestion.parentTitle"></div>
                  </div>
                  <div class="questionTitle">
                    <span class="questionTypeTips" style="min-width: 54px">{{$setDictionary('TOOL_QUESTION_TYPE',currentQuestion.questionType)}}</span>
                    <div class="questionTitleText" v-html="currentQuestion.questionName"></div>
                  </div>
                  <!-- 单选 --><!--判断-->
                  <div class="questionOptions" v-if="currentQuestion.questionType == '10'||currentQuestion.questionType == '30'" :key="currentQuestion.paperQuestionId">
                    <div :class="item.key === currentQuestion.standardAnswer?'optionItem active':'optionItem'" v-for="(item,index) in JSON.parse(currentQuestion.optionJson)" :key="index">
                      <span class="optionNum">{{ item.key }}</span>
                      <span class="optionInfo">{{ item.content }}</span>
                    </div>
                  </div>
                  <!--多选-->
                  <div class="questionOptions" v-if="currentQuestion.questionType == '20'" :key="currentQuestion.paperQuestionId">
                    <div :class="currentQuestion.standardAnswer.indexOf(item.key) != -1?'optionItem active':'optionItem'" v-for="(item,index) in JSON.parse(currentQuestion.optionJson)" :key="index">
                      <span class="optionNum">{{ item.key }}</span>
                      <span class="optionInfo">{{ item.content }}</span>
                    </div>
                  </div>
                  <div>
                  </div>
                  <!--填空-->
                  <div class="questionCompletions" v-if="currentQuestion.questionType == '40'" :key="currentQuestion.paperQuestionId">
                    <div v-for="(item,index) in currentQuestion.standardAnswer.split('####')" :key="index">
                      <div class="completionItem">
                        <div class="completionTitle">{{index+1}}.学员答案</div>
                        <div class="completionValue">
                          <div class="answer" v-if="currentQuestion.answerContent" v-html="currentQuestion.answerContent.split('####')[index]"></div>
                          <div class="answer" v-else></div>
                        </div>
                      </div>
                      <div class="completionItem">
                        <div class="completionTitle success">{{index+1}}.正确答案</div>
                        <div class="completionValue">
                          <div v-if="currentQuestion.fixedField" class="answer" v-html="item"></div>
                          <div v-else class="answer" v-html="currentQuestion.standardAnswer.split('####').join('、')"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--简答--><!--计算--><!--论述--><!--案例分析-->
                  <div class="questionCompletions" v-if="currentQuestion.questionType == '50'||currentQuestion.questionType == '60'||currentQuestion.questionType == '64'||currentQuestion.questionType == '68'" :key="currentQuestion.paperQuestionId">
                    <div class="completionItem">
                      <div class="completionTitle">学员答案</div>
                      <div class="completionValue">
                        <div class="answer" v-html="currentQuestion.answerContent"></div>
                      </div>
                    </div>
                    <div class="completionItem">
                      <div class="completionTitle success">正确答案</div>
                      <div class="completionValue">
                        <div class="answer" v-html="currentQuestion.standardAnswer"></div>
                      </div>
                    </div>
                  </div>
                  <!--得分结果 -->
                  <div v-if="!!currentQuestion.answerId" class="flexcb result" style="margin: 20px 20px 20px 50px;">
                    <div style="line-height:1.8em">
                      <template v-if="currentQuestion.questionType == '10'||currentQuestion.questionType == '20'||currentQuestion.questionType == '30'">
                        <p>得分：<span style="color: #2878FF;">{{currentQuestion.answerScore}}</span> 分</p>
                        <p>正确答案：<span style="color: #11AA66;">{{currentQuestion.standardAnswer}}</span></p>
                        <p class="flexca">
                          考生答案：
                          <span :class="currentAnswerStateClass">{{currentQuestion.answerContent}}</span>
                          <span class="symbol"></span>
                        </p>
                      </template>
                      <p>
                        评判结果：
                        <span style="vertical-align:middle">
                          <div class="df" v-if="currentQuestion.answerScore!=undefined">
                            <div class="lf err" v-if="currentQuestion.answerState===0"><img width="16" height="16" src="../../../assets/res-error.png" alt=""></div>
                            <div class="lf sucess" v-else><img width="16" height="16" src="../../../assets/res-sucess.png" alt=""></div>
                            <div class="rf" :class="currentQuestion.answerState===0?'err':'sucess'">{{currentAnswerStateStr[currentQuestion.answerState]}}</div>
                          </div>
                        </span>
                      </p>
                    </div>
                    <div class="df" style="align-items: stretch;align-self: end;min-width:140px">
                      <div class="pfst">判分</div>
                      <el-input-number v-model="currentQuestion.answerScore" @change="myAnswerChange(currentQuestion)" size="mini" :precision="2" :controls="false" :min="0" :max="currentQuestion.score" :disabled="['1','-1'].includes(userJson.roleId)"></el-input-number>
                      <div class="pfrst">本题共{{currentQuestion.score}}分</div>
                    </div>
                  </div>
                  <div v-else style="margin: 20px 20px 20px 50px;color: #F56C6C;text-align:right">
                    该题学员未作答，记 0 分
                  </div>
                </div>
              </div>
              <div class="flexac" style="background: #fafafa;padding: 0 0 20px 0;">
                <el-button type="primary" :disabled="currentQuestionIndex===0" @click="changeQuestion('prev')">上一题</el-button>
                <el-button type="primary" :disabled="markInfo.answerList&&currentQuestionIndex===markInfo.answerList.length-1" @click="changeQuestion('next')">下一题</el-button>
              </div>
              <div class="record">
                <div>阅卷记录</div>
                <template v-if="recordList.length>0">
                  <div v-for="(item,index) in recordList" :key="index">
                    <div class="df">
                      <div>{{item.fullname}}</div>{{item.score}}分
                    </div>
                    <div>{{item.createTime}}</div>
                  </div>
                </template>
                <div v-else>
                  无
                </div>
              </div>
            </div>
            <div>
              <div class="card_box w230 noflag" style="padding: 0 20px;">
                <div class="listItem" v-if="markInfo.userName&&((markInfo.scoringShowUser&&query.markType=='01')||query.markType=='02')">
                  <div class="item1">学员姓名</div>
                  <div class="item2">{{markInfo.userName}}</div>
                </div>
                <div class="listItem" v-if="markInfo.userCandidateNo&&((markInfo.scoringShowUser&&query.markType=='01')||query.markType=='02')">
                  <div class="item1">准考证号</div>
                  <div class="item2">{{markInfo.userCandidateNo}}</div>
                </div>
                <div class="listItem">
                  <div class="item1">考试成绩</div>
                  <div class="item2">{{markInfo.totalScore}}</div>
                </div>
                <template v-if="query.markType=='01'">
                  <div class="listItem">
                    <div class="item1">已阅题目(共{{markInfo.readScore}}分)</div>
                    <div class="item2">{{markInfo.readNum}}</div>
                  </div>
                  <div class="listItem">
                    <div class="item1">未阅题目(共{{markInfo.unReadScore}}分)</div>
                    <div class="item2">{{markInfo.unReadNum}}</div>
                  </div>
                </template>
              </div>
              <div class="btnWrap" v-if="!['1','-1'].includes(userJson.roleId)">
                <div>
                  <el-button type="primary" style="width:100%;margin-bottom: 20px;" @click="submitAllScore()">{{query.markType=='01'?'阅卷':'复核'}}完成</el-button>
                </div>
                <div v-if="query.markType=='01'" style="text-align: center;">
                  共 {{markInfo.readPeople + markInfo.unReadPeople}} 人 已阅 {{markInfo.readPeople}} 人
                </div>
                <div>
                  <el-button type="primary" plain :disabled="!markInfo.examinationPerformanceIdPer" @click="goQuestion(markInfo.examinationPerformanceIdPer)" style="width:100%;margin-bottom: 20px;">上一人</el-button>
                  <el-button type="primary" plain :disabled="!markInfo.examinationPerformanceNext" @click="goQuestion(markInfo.examinationPerformanceNext)" style="width:100%;margin: 0;">下一人</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: 'examination_reviewPaperDetail',
  data () {
    return {
      query: {
        examinationId: '', // 考试id
        examinationPerformanceId: '', // 最新考试轨迹id
        markType: '', // 01阅卷;复核02
      },
      markInfo: {},
      // 当前显示题的id
      currentQuestionId: '',
      // 当前显示大题的索引
      currentParperIndex: '',
      // 阅卷记录
      recordList: [],
      currentAnswerStateStr: {
        0: '错误',
        1: '正确',
        2: '部分正确',
      },
      // 是否阅卷完成
      isComplete:false,
    }
  },
  created () {
    this.query = this.$route.query
    this.getMarkInfo()
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
    // 当前题目索引
    currentQuestionIndex(){
      return this.markInfo?.answerList&&this.markInfo?.answerList.findIndex(e => e.paperQuestionId == this.currentQuestionId)
    },
    classFliter () {
      return function (btnItem) {
        let res = []
        if (this.query.markType == '01') {
          if (btnItem.markUserId) {
            res.push('haveRead')
          }
        }
        if (this.query.markType == '02') {
          if (btnItem.answerState == 0) {
            res.push('error')
          } else if (btnItem.answerState == 1) {
            res.push('correct')
          } else if (btnItem.answerState == 2) {
            res.push('partOfRight')
          }
        }
        if (this.currentQuestionId == btnItem.paperQuestionId) {
          res.push('current')
        }
        return res
      }
    },
    // 当前题目数据
    currentQuestion () {
      if (this.currentQuestionId && this.markInfo?.answerList?.length > 0) {
        let res = this.markInfo.answerList.find(e => e.paperQuestionId == this.currentQuestionId)
        return res ?? {}
      } else {
        return {}
      }
    },
    // 评判结果
    currentAnswerStateClass () {
      if (JSON.stringify(this.currentQuestion) != '{}') {
        const { answerState } = this.currentQuestion
        if (answerState == 0) {
          return 'error'
        } else if (answerState == 1) {
          return 'correct'
        } else if (answerState == 2) {
          return 'partOfRight'
        }
      } else {
        return ''
      }
    },
    // 当前大题数据
    currentParper () {
      if (this.markInfo?.partitionList?.length > 0) {
        let res = this.markInfo.partitionList[this.currentParperIndex]
        return res ?? {}
      } else {
        return {}
      }
    }
  },
  methods: {
    // 评分详情
    getMarkInfo () {
      let url = "";
      // 运营
      if(['1','-1'].includes(this.userJson.roleId)){
        url="/run/examination/answer";
      // 机构
      } else {
        url="/biz/examination/scoring/markInfo";
      }
      this.$post(url, this.query, 3000, true, 6)
        .then((res) => {
          if (res.status == '0') {
            this.markInfo = res.data
            this.currentQuestionId = this.currentQuestionId || this.markInfo.answerList[0].paperQuestionId
            this.currentParperIndex = this.currentParperIndex || 0
            this.query.examinationPerformanceId || (this.query.examinationPerformanceId = res.data.examinationPerformanceId)
            if(this.currentQuestion.answerId){
              this.selectMarkLog(this.currentQuestion.answerId)
            }else{
              this.recordList = []
            }
          }
        })
        .catch((err) => {
          // this.$router.back()
          return;
        });
    },
    // 答题卡点击
    async btnItemClick (btnItem, index) {
      if (btnItem.paperQuestionId == this.currentQuestionId) {
        return
      }
      if (this.currentQuestion.answerScore != undefined && this.currentQuestion.answerId) {
        let answerMarkDTOList = [
          {
            answerId: this.currentQuestion.answerId,
            score: this.currentQuestion.answerScore,
            answerState: this.currentQuestion.answerState,
          }
        ]
        // 非 运营端才调
        if(!['1','-1'].includes(this.userJson.roleId)){
          await this.submitScore(answerMarkDTOList)
        }
        this.getMarkInfo()
        this.currentQuestionId = btnItem.paperQuestionId
        this.currentParperIndex = index
      } else {
        this.currentQuestionId = btnItem.paperQuestionId
        this.currentParperIndex = index
        this.currentQuestion.answerId && this.selectMarkLog(this.currentQuestion.answerId)
      }
    },
    //判分
    myAnswerChange ({ answerId, answerScore, score }) {
      const index = this.markInfo.answerList.findIndex(e => e.answerId == answerId)
      this.$set(this.markInfo.answerList[index], 'answerState', answerScore == 0 ? 0 : answerScore === score ? 1 : 2)
    },
    // 获取当前题目阅卷记录
    selectMarkLog (answerId) {
      let data = {
        answerId,
        examinationPerformanceId: this.query.examinationPerformanceId,
        markType: this.query.markType,
      }
      this.$post("/biz/examination/exam-bank-examination-mark-log/selectMarkLog", data, 3000, true, 6)
        .then((res) => {
          if (res.status == '0') {
            this.recordList = res.data
          }
        })
        .catch((err) => {
          return;
        });
     },
    // 提交评分
    submitScore (answerMarkDTOList) {
      if (answerMarkDTOList.length === 0) {
        this.$message.error('请填写分数')
        return false
      }
      const data = {
        answerMarkDTOList,
        ...this.query
      }
      return this.$post("/biz/examination/scoring/submitMark", data, 3000, true, 6)
    },
    // 阅卷完成
    markFinished(){
      return this.$post("/biz/examination/scoring/markFinished", { examinationPartitionId: this.query.examinationPerformanceId, examinationId: this.query.examinationId }, 3000, true, 6)
        .then(res=>{
          this.isComplete = true
          this.getMarkInfo()
        })
    },
    // 提交全部
    async submitAllScore () {
      let answerMarkDTOList = this.markInfo.answerList.map(e => {
        return {
          answerId: e.answerId,
          score: e.answerScore,
          answerState: e.answerState,
        }
      })
      let answerMarkDTOListFliter = answerMarkDTOList.filter(e => e.score != undefined && e.answerId)
      let answerMarkDTOListNo = answerMarkDTOList.filter(e => e.score === undefined)
      if (answerMarkDTOListFliter.length === 0) {
        this.$message.error('请填写分数')
        return false
      }
      if (this.query.markType == '01') {
        this.$confirm('该学员是否阅卷完成，阅卷完成后不可修改分数!', '提示', {
          distinguishCancelAndClose:true,
          confirmButtonText: '阅卷完成',
          cancelButtonText: '仅提交评分',
          type: 'warning'
        }).then(async () => {
          await this.submitScore(answerMarkDTOListFliter)
          await this.getMarkInfo()
          if(answerMarkDTOListNo.length>0){
            this.$message.error('存在试题未判分，请将全部试题判分后再点击阅卷完成')
          }else{
            this.$message.success('操作成功！')
            await this.markFinished()
          }
        }).catch(async (action) => {
          if(action === 'cancel'){
              await this.submitScore(answerMarkDTOListFliter)
              this.$message.success('操作成功！')
              await this.getMarkInfo()
          }
        });
      }
      if (this.query.markType == '02') {
        await this.submitScore(answerMarkDTOListFliter)
        this.$message.success('操作成功！')
        await this.getMarkInfo()
      }
    },
    // 上/下一题
    changeQuestion(type){
      let btnItem,index;
      let i = this.currentParper.answerBOList.findIndex(e=>e.paperQuestionId == this.currentQuestionId)
      console.log(i);
      switch (type) {
        case 'prev':
          // 大题索引等于0获取上一个大题对象数组最后一个，不等于0获取当前数组--
          if(i===0) {
            index = this.currentParperIndex-1
            let arr = this.markInfo.partitionList[index].answerBOList
            btnItem = arr[arr.length-1]
          }else{
            btnItem = this.currentParper.answerBOList[i-1]
            index = this.currentParperIndex
          }
          break;
        case 'next':
          // 反之
          if(i===this.currentParper.answerBOList.length-1) {
            index = this.currentParperIndex+1
            let arr = this.markInfo.partitionList[index].answerBOList
            btnItem = arr[0]
          }else{
            btnItem = this.currentParper.answerBOList[i+1]
            index = this.currentParperIndex
          }
          break;

        default:
          console.log('你xx,穷哈哈');
          return
      }
      this.btnItemClick(btnItem,index)
    },
    // 上/下一人
    async goQuestion (examinationPerformanceId) {
      if (this.currentQuestion.answerScore != undefined && this.currentQuestion.answerId&&!this.isComplete) {
        let answerMarkDTOList = [
          {
            answerId: this.currentQuestion.answerId,
            score: this.currentQuestion.answerScore,
            answerState: this.currentQuestion.answerState,
          }
        ]
        await this.submitScore(answerMarkDTOList)
      }
      this.query.examinationPerformanceId = examinationPerformanceId
      this.currentQuestionId = this.$options.data().currentQuestionId
      this.currentParperIndex = this.$options.data().currentParperIndex
      this.isComplete = this.$options.data().isComplete
      this.recordList = this.$options.data().recordList
      this.getMarkInfo()
    },
  },
}
</script>
<style scoped lang="less">
.answerPage {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  overflow-y: auto;
}
.mt20 {
  margin-top: 20px;
}
.mr20 {
  margin-right: 20px;
}
.examInfo {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background-color: #fafafa;
  font-size: 16px;
  color: #333;
}
.content {
  flex: 1;
  display: flex;
  .content_row {
    margin-top: 20px;
    width: 1180px;
    display: flex;
    flex: 1;
    justify-content: center;
    .card_box {
      background: #fafafa;
      border-radius: 4px;
      padding: 20px 0;
      position: relative;
      text-align: left;
      &.w230 {
        width: 230px;
      }
      &.w630 {
        width: 630px;
      }
      &.w280 {
        width: 280px;
      }
      &:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 15px;
        background: #2878ff;
        top: 20px;
        left: 0;
      }
      &.noflag {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        &:before {
          background: transparent;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      h3 {
        margin-left: 20px;
        line-height: 18px;
        font-size: 16px;
        color: #333;
        font-weight: bold;
        margin-bottom: 12px;
      }
    }
  }
  .timeRemainingTitle {
    color: #ff6030;
    font-size: 16px;
    margin-top: 2px;
  }
  .timeRemaining {
    margin-top: 8px;
    color: #ff6030;
    font-size: 24px;
    font-weight: bold;
  }
  .timeRemainingBtn {
    margin-top: 20px;
    .el-button {
      width: 105px;
    }
    .el-button--primary.is-plain {
      background-color: #e8f3ff;
      border-color: #e8f3ff;
      color: #2878ff;
    }
  }
  .studentInfo {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .infoText {
      margin-left: 20px;
      font-size: 14px;
      color: #333;
      & > div {
        line-height: 20px;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      span {
        color: #666;
      }
    }
  }
  .answerCards {
    padding-top: 8px;
    margin: 0 20px;
    .answerCardsItem {
      margin-bottom: 10px;
      .itemTitle {
        font-size: 14px;
        color: #333;
        margin-bottom: 11px;
      }
      .btnList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .btnItem {
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #f1f1f1;
          background-color: #f1f1f1;
          margin-bottom: 10px;
          margin-right: 10px;
          color: #333;
          cursor: pointer;
          position: relative;
          &:nth-child(5n) {
            margin-right: 0;
          }
          &.haveRead {
            &::before {
              content: '';
              position: absolute;
              right: 3px;
              bottom: 3px;
              width: 10px;
              height: 10px;
              background: url('../../../assets/haveRead1.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.correct {
            border: 1px solid #11aa66;
            background: rgba(17, 170, 102, 0.3);
            color: #11aa66;
          }
          &.partOfRight {
            border: 1px solid #84d1af;
            background: transparent;
            color: #11aa66;
          }
          &.error {
            border: 1px solid #fcbfb5;
            background: rgba(255, 74, 46, 0.05);
            color: #ff4a2e;
          }
          &.current {
            border: 1px solid #2878ff;
            background: #f4f6f9;
            color: #2878ff;
          }
        }
      }
    }
  }
  .answerCardsBtn {
    background: #f1f1f1;
    padding: 15px 0;
    border-radius: 0 0 4px 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    div {
      padding-left: 20px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: -4px;
        top: 50%;
        width: 16px;
        height: 16px;
        margin-top: -7px;
        box-sizing: border-box;
        border-radius: 2px;
      }
    }
    .noAnswer::before {
      border: 1px solid #666666;
    }
    .correct::before {
      border: 1px solid #11aa66;
      background: rgba(17, 170, 102, 0.3);
    }
    .partOfRight::before {
      border: 1px solid #84d1af;
    }
    .error::before {
      border: 1px solid #ff4a2e;
      background: rgba(255, 74, 46, 0.05);
    }
    .didNotRead::before {
      border: 1px solid #666666;
    }
    .haveRead::before {
      background: url('../../../assets/haveRead2.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  .listItem {
    padding: 16px 0;
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:last-of-type {
      border-bottom: none;
    }
    .item1 {
      font-size: 14px;
      color: #666666;
      margin-bottom: 8px;
    }
    .item2 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
  }
  .btnWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    height: 30%;
    min-height: 200px;
  }
  //答题区域

  .questionType {
    margin: 0 20px;
    word-break: break-all;
    .questionTitle,
    .parentQuestionTitle {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .questionTypeTips {
        font-size: 12px;
        color: #2878ff;
        padding: 3px 8px;
        background: #f4f6f9;
        border: 1px solid #2878ff;
        border-radius: 4px;
        margin-right: 8px;
      }
      .questionTitleText {
        font-size: 16px;
        color: #333;
      }
    }
    .parentQuestionTitle {
      margin-bottom: 20px;
    }
    .questionOptions {
      margin-left: 50px;
      .optionItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 8px;
        .optionNum {
          display: flex;
          width: 22px;
          height: 22px;
          min-width: 22px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: #f1f1f1;
          font-size: 12px;
          color: #666;
          margin-right: 4px;
        }
        .optionInfo {
          padding: 8px 0;
          font-size: 14px;
          color: #666;
          line-height: 1.4;
        }
        &.active {
          .optionNum {
            background-color: #11aa66;
            color: #fff;
          }
          .optionInfo {
            color: #11aa66;
          }
        }
      }
    }
    .questionCompletions {
      margin: 0 50px;
      .completionItem {
        margin: 12px 0;
        border: 1px solid #f1f1f1;
        .completionTitle {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 23px;
          padding: 0 8px;
          background-color: #f4f6f9;
          border-radius: 2px 2px 0px 0px;
          font-size: 12px;
          color: #2878ff;
          &.success {
            color: #11aa66;
            background-color: #edf5f2;
          }
        }
        .completionValue {
          padding: 10px 0 0 8px;
          .answer {
            border: 0;
            font-size: 14px;
            min-height: 5em;
            color: #333;
            background-color: transparent;
            word-wrap: break-word;
          }
        }
      }
    }
  }
  .hasParent {
    .questionTitle {
      margin-left: 50px;
    }
    .questionOptions,
    .questionCompletions {
      margin-left: 100px;
    }
  }
  .el-input-number--mini {
    width: 70px;
    /deep/ .el-input__inner {
      border: 1px solid #2878ff;
      border-left: none;
      border-radius: 0 4px 4px 0;
      color: #2878ff;
      font-weight: bold;
    }
  }
  .pfst {
    background: #2878ff;
    border-radius: 4px 0 0 4px;
    color: #fff;
    padding: 4px;
  }
  .pfrst {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #999999;
    margin-left: 4px;
  }
  .result {
    .correct {
      position: relative;
      color: #11aa66;
    }
    .partOfRight {
      position: relative;
      color: #11aa66;
    }
    .error {
      position: relative;
      color: #ff4a2e;
    }
    .correct + .symbol {
      display: inline-block;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      background: url('../../../assets/sucess1.png') no-repeat;
      background-size: 100% 100%;
      color: #11aa66;
    }
    .partOfRight + .symbol {
      display: inline-block;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      background: url('../../../assets/sucess2.png') no-repeat;
      background-size: 100% 100%;
      color: #11aa66;
    }
    .error + .symbol {
      content: '';
      display: inline-block;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      background: url('../../../assets/error1.png') no-repeat;
      background-size: 100% 100%;
      color: #ff4a2e;
    }
    .lf {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px 0 0 4px;
    }
    .rf {
      height: 26px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 4px 4px 0;
    }
    .lf.sucess {
      background: #11aa66;
    }
    .rf.sucess {
      border: 1px solid #11aa66;
      color: #11aa66;
    }
    .lf.err {
      background: #ff4a2e;
    }
    .rf.err {
      border: 1px solid #ff4a2e;
      color: #ff4a2e;
    }
  }
  .record {
    color: #999999;
    line-height: 2em;
    padding: 20px 20px 50px;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .df > div {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>